export const CATEGORY_HOMEPAGE = 'homepage'
export const CATEGORY_HOMEPAGE_REACT = 'homepage_react'
export const CATEGORY_RUBRIC = 'rubric'
export const CATEGORY_ARTICLE = 'article'
export const CATEGORY_RUBRIC_AND_ARTICLE = 'rubric_and_article'
export const CATEGORY_EXPORT = 'export'
export const CATEGORY_VIDEO = 'video'
export const AVAILABLE_TYPES = [
  {
    id: CATEGORY_HOMEPAGE,
    title: 'Homepage'
  },
  {
    id: CATEGORY_HOMEPAGE_REACT,
    title: 'Homepage React'
  },
  {
    id: CATEGORY_RUBRIC,
    title: 'Rubric'
  },
  {
    id: CATEGORY_ARTICLE,
    title: 'Article'
  },
  {
    id: CATEGORY_RUBRIC_AND_ARTICLE,
    title: 'Rubric and article'
  },
  {
    id: CATEGORY_EXPORT,
    title: 'Export'
  },
  {
    id: CATEGORY_VIDEO,
    title: 'Video'
  }
]
